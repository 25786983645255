module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","manualInit":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"ccpaAcknowledgeOnDisplay":true,"whitelabel":false,"lang":"de","siteId":2251601,"enableCcpa":true,"countryDetection":true,"reloadOnConsent":false,"cookiePolicyId":80467400,"cookiePolicyUrl":"/datenschutz","banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"top","acceptButtonColor":"#EA6509","acceptButtonCaptionColor":"white","customizeButtonColor":"#D5D5D5","customizeButtonCaptionColor":"#5E5E5E","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","textColor":"#5E5E5E","backgroundColor":"white"}}},
    }]
